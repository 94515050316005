import {useState, useEffect} from 'react'
import useHttp from './hooks/useHttp'
import usePageIdentifier from './hooks/usePageIdentifier'
import _ from "lodash";
import TilesGrid from './components/NisTiles/TilesGrid'
import TilesSlider from './components/NisTiles/TilesSlider'
import TilePlaceholder from './components/NisTiles/TilePlaceholder';
import Spinner from './components/UI/Spinner'

function App() {
  const [allNisData,setAllNisData] = useState()
  const [nisType,setNisType] = useState()
  const [version,setVersion] = useState()
  const [filterTitles,setFilterTitles] = useState([])
  const [firstFilter,setFirstFilter] = useState([])
  const [filterheading,setFilterheading] = useState()
  const [heroBannerImg,setHeroBannerImg] = useState()
  const [heroBannerImgMobile,setHeroBannerImgMobile] = useState()
  const [heroBannerAltText,setHeroBannerAltText] = useState()
  const [menuColor,setMenuColor] = useState('black')
  const [bannerUrl,setBannerUrl] = useState()
  const [longDescription,setLongDescription] = useState()
  const [svgImage,setSvgImage] = useState()
  const [filteredData, setFilteredData] = useState(allNisData)
  const {error,isLoading, sendRequest } = useHttp()
  const{PageUrl,pageType,isHomepage} = usePageIdentifier()

  //const testUrl = 'https://new-in-store-uat-default-rtdb.firebaseio.com/data/Content.json'
  //const testUrlVersion = 'https://new-in-store-uat-default-rtdb.firebaseio.com/data/Version.json'
  //const testSvg = 'https://new-in-store-uat-default-rtdb.firebaseio.com/data/SVG.json'
 

  const prodtUrl = 'https://prod---new-in-store-default-rtdb.firebaseio.com/data/Content.json'
  const prodUrlVersion = 'https://prod---new-in-store-default-rtdb.firebaseio.com/data/Version.json'
  const prodSvg = 'https://prod---new-in-store-default-rtdb.firebaseio.com/data/SVG.json'

  const nisData = (data) => {
    let validData = [];
    const data_arr = _.values(data);
    //console.log("data_arr:", data_arr);
    const filteredData_arr = data_arr.filter(function (el) {
      return el != null;
    });
    filteredData_arr.forEach((el) => {
      //console.log('el.pageurl:',el.PageURL)
      if (el.PageURL.trim() === PageUrl) {
        //console.log("show this one", el);
        if (el.tileImage.trim() !== "" && el.PageURL.trim() !== "") {
          validData.push(el);
        }
      }
    });
    setAllNisData(validData);
    setFilteredData(validData); // update filteredData with the latest value of allNisData
  };

  const versionData = vData =>{
    const data_arr = _.values(vData);
  //console.log('version data:',data_arr)
    const filteredData_arr = data_arr.filter(function (el) {
      return el != null;
    })
    filteredData_arr.map(d => {
 
      if(PageUrl === d.pageURL){
        // console.log('set version version:',d)
        setVersion(d.version)
        setLongDescription(d.longDescription)
        setFilterTitles(d.menu_headers)
        setFilterheading(d.menu_title)
        setHeroBannerImg(d.hero_banner_image)
        setHeroBannerImgMobile(d.hero_banner_image_mobile)
        setHeroBannerAltText(d.hero_banner_alt_text)
        setMenuColor(d.menu_header_colors)
        setBannerUrl(d.hero_banner_url)
      }
     
    }) 
  }

 
  
  const svgData = sData => {
    const svg_arr = _.values(sData);
    const filteredSvg_arr = svg_arr.filter(function (el) {
      return el != null;
    })
    //console.log('SVG data:',filteredSvg_arr)
    setSvgImage(filteredSvg_arr)
  }

  const L1CategoryNavWidget = document.querySelectorAll('.L1CategoryNavWidget') && document.querySelectorAll('.L1CategoryNavWidget ').length > 0


  useEffect(() => {
    sendRequest({ url: prodtUrl }, nisData);
    sendRequest({ url: prodUrlVersion }, versionData);
    sendRequest({ url: prodSvg }, svgData);
    if (version && version !== undefined && version !== "") {
      setNisType(version);
    } else {
      if (
        pageType.indexOf("new-in-store") !== -1 ||
        pageType.indexOf("storecash") !== -1
      ) {
        setNisType("gridType");
      } else if (
        pageType.indexOf("homepage") !== -1 ||
        pageType.indexOf("l1") !== -1
      ) {
        setNisType("sliderType");
      }
    }
    if(filterTitles && filterTitles !== undefined ) {
    setFirstFilter(filterTitles.toString().split(",")[0]) 

  }
  }, [sendRequest, pageType, nisType, version]);

//console.log('APP nisType:',nisType,'pageType:',pageType,'allNisData:',allNisData,'version:',version, 'filterTitles ',filterTitles )



const filterTilesHandler = (filter) => {
  //console.log('filter clicked:',filter)
  if (filter === firstFilter) {
      setFilteredData(allNisData);
    } else {
      const filtered = allNisData.filter((item) =>
        item.filter.toLowerCase().includes(filter.toLowerCase())
      );
      setFilteredData(filtered);
    }
}

//console.log('L1CategoryNavWidget in App:',L1CategoryNavWidget)
return (
  <div className="ggds nisTiles">
    <div className="nisWrapper bg-graylight-5 fullwidth">
      {isLoading ? (
        <>
          <Spinner />
         {nisType === 'sliderType' ? <TilePlaceholder L1CategoryNavWidget={L1CategoryNavWidget}/>: null}
        
        </>
      ) : (
        <>
          {allNisData && allNisData!== undefined && allNisData.length > 0 && nisType !== undefined && (
            // Render slider or grid based on nisType
            <>
              {nisType === 'sliderType' && (
                <TilesSlider
                  allNisData={allNisData}
                  pageType={pageType}
                  L1CategoryNavWidget={L1CategoryNavWidget}
                  isHomepage={isHomepage}
                  isLoading={isLoading}
                  svgImage={svgImage}
                  PageUrl={PageUrl}
                  longDescription={longDescription}
                />
              )}
              {nisType === 'gridType' && (
                <TilesGrid
                  allNisData={allNisData}
                  pageType={pageType}
                  svgImage={svgImage}
                  L1CategoryNavWidget={L1CategoryNavWidget}
                  isHomepage={isHomepage}
                  isLoading={isLoading}
                  PageUrl={PageUrl}
                  filteredData={filteredData}
                  filterTitles={filterTitles}
                  filterheading={filterheading}
                  heroBannerImg={heroBannerImg}
                  heroBannerImgMobile={heroBannerImgMobile}
                  heroBannerAltText={heroBannerAltText}
                  menuColor={menuColor}
                  bannerUrl={bannerUrl}
                  longDescription={longDescription}
                  filterTilesHandler={filterTilesHandler}
                  firstFilter={firstFilter}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  </div>
);

}

export default App;
