import React from "react";
import tagginhHelper from '../helper/tagginhHelper'

const CustomTile = props => {
//console.log('props.customTileData:',props.customTileData)
 const {tileImage,imageAlt,buttonURL,hero_tile_mobile_image} = props.customTileData
 const taggingUrl = props.taggingUrl
 const position = +props.index + 1

 //console.log('CUSTOM',taggingUrl,buttonURL,position)

 const innerCustomTile = <picture>
 <source media="(max-width: 768px)" srcSet={hero_tile_mobile_image}/>
   <img
     className="width-100"
     src={tileImage}
     alt={imageAlt}
   />
 </picture>

  return (
    <div className='custom-tile'>
      {buttonURL ? <a href={buttonURL} onClick={()=> tagginhHelper(taggingUrl,2,imageAlt,null, buttonURL, position)}>
        {innerCustomTile}
      </a> : <div>
        {innerCustomTile}
      </div> }
      
    </div>
  );
};

export default CustomTile;