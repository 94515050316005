import React, { useState, useEffect } from 'react';
import Tile from './Tile';
import Carousel from "react-multi-carousel";
import Header from './Header';
import './carouselStyling.css';

const TileSlider = props => {
  const [largeTile, setLargeTile] = useState();

  const { allNisData: nisData, pageType, svgImage, longDescription,isHomepage , PageUrl,L1CategoryNavWidget} = props;
  //console.log('svgImage in tile slider:',svgImage)
  const categoryTitle = nisData[0].PageURL;
  const taggingUrl = pageType === 'homepage' ? 'https://www.thegoodguys.com.au' : `https://www.thegoodguys.com.au/${categoryTitle}`;

  const arrowTaggingHandler = (arrowDirection) => {
    const label = arrowDirection === 'right' ? 'Arrow Slider - Next Arrow' : 'Arrow Slider - Prev Arrow';
    window.dataLayer.push({
      'event': 'whats_new_in_store',
      'TGGEventCategory': 'Whats New',
      'TGGEventAction': taggingUrl,
      'TGGEventLabel': label
    });
  };
  
  let responsiveNis;
  if (!props.L1CategoryNavWidget) {
    responsiveNis = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 1,
      },
      tabletLandscape: {
        breakpoint: { max: 1023, min: 768 },
        items: 3,
        slidesToSlide: 1
      },
      tabletPortrait: {
        breakpoint: { max: 767, min: 650 },
        items: 2,
        slidesToSlide: 1
      },
      mobileLarge: {
        breakpoint: { max: 649, min: 460 },
        items: 2,
        slidesToSlide: 1
      },
      mobile: {
        breakpoint: { max: 459, min: 250 },
        items: 1.2,
        slidesToSlide: 1
      }
    };
  } else {
    responsiveNis = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3,
      },
      tabletLandscape: {
        breakpoint: { max: 1023, min: 646 },
        items: 2,
        slidesToSlide: 2
      },
      tabletPortrait: {
        breakpoint: { max: 645, min: 375 },
        items: 1.5,
        slidesToSlide: 1
      },
      mobile: {
        breakpoint: { max: 374, min: 250 },
        items: 1.2,
        slidesToSlide: 1
      }
    };
  }

  let nisTiles;
  let largeTileData = [];
  let withShowMore = [];

  if (nisData && nisData !== undefined) {
    nisTiles = nisData.map((el, index) => {
      largeTileData.push(el.tileHeading.trim() !== '');
      withShowMore.push(el.tileCopy.length > 41);
      return (
      
          <Tile 
            key={el.ID} 
            tiledata={el} 
            tileType='slider' 
            pageType={pageType} 
            taggingUrl={taggingUrl} 
            longDescription={longDescription} 
            largeTile={largeTile} 
            index={index} 
            isHomepage={isHomepage}
            PageUrl={PageUrl}
          /> 
     
      );
    });
  } else {
    nisTiles = null;
  }

  useEffect(() => {
    if (largeTileData.indexOf(true) !== -1) {
      setLargeTile(true);
    } else {
      setLargeTile(false);
    }
  }, []);

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const { carouselState: { deviceType } } = rest;
    return (
      <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right" onClick={() => {
        arrowTaggingHandler('right');
        onClick();
      }} />
    );
  };

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const { carouselState: { deviceType } } = rest;
    return (
      <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" onClick={() => {
        arrowTaggingHandler('left');
        onClick();
      }} />
    );
  };

  const sliderClassList = props.L1CategoryNavWidget !== true ? 'container nisTiles_slider padd-top-5' : 'nisTiles_slider marg-bottom-20';

  return (
    <div className={sliderClassList}>
      <Header svgImage={svgImage} pageType={pageType} categoryTitle={categoryTitle} PageUrl={PageUrl} L1CategoryNavWidget={L1CategoryNavWidget}/>
      {nisData && nisData !== undefined ? (
        <Carousel
          arrows={true}
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsiveNis}
          ssr={true}
          infinite={false}
          partialVisible={true}
          autoPlay={false}
          autoPlaySpeed={props.autoPlaySpeed}
          keyBoardControl={true}
          customTransition="transform 800ms ease-in-out"
          transitionDuration={800}
          containerClass="carousel-container"
          itemClass="carousel-item-padding-40-px"
        >
          {nisTiles}
        </Carousel>
      ) : null}
    </div>
  );
};

export default TileSlider;

