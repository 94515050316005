const Button = (props) => {
    const { buttonURL, buttonColour, buttonDisabled } = props;
  
    const btnColor = buttonColour || '#ee32a2';
  
    // Helper function to convert hex color to RGBA format
    function hexToRGBA(hex, opacity) {
      const hexValue = hex.replace('#', '');
      const r = parseInt(hexValue.substring(0, 2), 16);
      const g = parseInt(hexValue.substring(2, 4), 16);
      const b = parseInt(hexValue.substring(4, 6), 16);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
  
    const rgbaColor = hexToRGBA(btnColor, 0.5);
  
    //console.log('btnColor:', btnColor, 'rgbaColor:', rgbaColor);
    return (
      <div className="pos-relative nisBtn">
        {buttonURL && !buttonDisabled ? (
          <a href={buttonURL} title={props.buttonTitle}>
            <button
              type="button"
              className="btn btn-sm"
              style={{ backgroundColor: btnColor }}
              onClick={props.onClick}
            >
              {props.children}
            </button>
          </a>
        ) : (
          <div className={buttonDisabled ? 'btn-noAction' : null}>
            <button
              type="button"
              className="btn btn-sm"
              style={{ backgroundColor: buttonDisabled ? rgbaColor : null }}
              onClick={props.onClick}
            >
              {props.children}
            </button>
          </div>
        )}
      </div>
    );
  };
  
  export default Button;
