const taggingHelper = (pageUrl, url, position) => {


    window.dataLayer.push({
      event: 'customer_interactions',
      type : `${pageUrl}`,
      category: 'new in store',
      action:'Tile Click',
      click_url: `${url}`,
      position: `${position}`
    })
  }
  
  export default taggingHelper