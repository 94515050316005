import {useState,useEffect} from 'react'
const Header =({ svgImage, pageType ,L1CategoryNavWidget,PageUrl}) => {
    const [isHover, setIsHover] = useState(false);
    const [catTitleLong, setCatTitleLong] = useState(false);


    let svgImageArr = []
    let svgColor = []
    const breadcrumb = document.getElementById('widget_breadcrumb')
    let categoryTitle_text
    if(pageType !== 'homepage' ){
       categoryTitle_text = breadcrumb.getElementsByTagName('li')[1].innerText

    }
    useEffect(() => {
        if(pageType !== 'homepage'){
            const catTitleLong = categoryTitle_text.length > 15
            setCatTitleLong(catTitleLong)
        }
    },[])

     const hpButtonTagging = () => {
        window.dataLayer.push({'event':'whats_new_in_store','TGGEventCategory':'Whats New','TGGEventAction':'https://www.thegoodguys.com.au' ,'TGGEventLabel':'view all new products , https://www.thegoodguys.com.au/new-in-store'})

        }

        if (Array.isArray(svgImage)) {
            svgImage.forEach(svg => {
                // Destructure SVG and headerColour directly from svg object
                const { SVG, headerColour, pageType: svgPageType } = svg;
             
                // Check if pageType is equal to svg.pageType
                if (pageType === svgPageType || pageType === 'l1') {
                    svgImageArr.push(SVG);
                    svgColor.push(headerColour);
                } else {
                    console.log('its a page without SVG!');
                }
            });
        }

    //console.log('svgImageArr in header:',svgImageArr)
    const svg = svgImageArr[0]
    let headerColor
    {svgColor.length > 0 ? headerColor=svgColor[0] : headerColor='#ee32a2'}


let categoryHeader

const handleMouseEnter = () => {
    setIsHover(true);
 }
 const handleMouseLeave = () => {
    setIsHover(false);
 }
const headerBtnStyle = isHover ? {
    color: '#fff',
    backgroundColor: headerColor || '#ee32a2'
  } : {
    color: headerColor || '#ee32a2',
    borderColor : headerColor || '#ee32a2'}


const headerText = <p className="text-bold disp-inline marg-right-5 homepageHeader_text" style={headerColor ? {color:headerColor} : {color:'#ee32a2'}}>WHAT'S</p>
const headerBtn = <button className="btn btn-ghost bg-white btn-nis marg-left-5" style={headerBtnStyle} onMouseEnter={handleMouseEnter}  onMouseLeave={handleMouseLeave}>VIEW ALL NEW PRODUCTS</button>
const newInStoreUrl = "https://www.thegoodguys.com.au/new-in-store"

//console.log('show header check:',(svg && svg !== undefined && svg.length > 0))

if(svg && svg !== undefined && svg.length > 0){
    if(L1CategoryNavWidget && L1CategoryNavWidget !== undefined){
        categoryHeader = <div className="categoryHeader" style={headerColor ? {color:headerColor} : {color:'#ee32a2'}}><p className={`text-bold disp-inline text-upper ${catTitleLong ? 'categoryHeader_text': 'categoryHeader_shorttext'}`} >WHAT’S&nbsp;</p><span dangerouslySetInnerHTML={{__html : svg}}></span><p  className={`text-bold disp-inline text-upper ${catTitleLong ? 'categoryHeader_text': 'categoryHeader_shorttext'}`}>&nbsp;IN {categoryTitle_text}</p></div>
    }
    else if (pageType === 'homepage' || pageType === 'new-in-store' ){
        categoryHeader = <div className="homepageHeader" >{headerText}<span dangerouslySetInnerHTML={{__html : svg}}></span><a href={newInStoreUrl}  onClick={hpButtonTagging}>{headerBtn}</a></div>
    }else if(pageType && pageType !== undefined && pageType === PageUrl) {
        //console.log('its not L1 or new-in-store or homepage')
        categoryHeader = <div className="homepageHeader" >{headerText}<span dangerouslySetInnerHTML={{__html : svg}}></span><a href={newInStoreUrl}  onClick={hpButtonTagging}>{headerBtn}</a></div>
    }


}
    return(
<>
{categoryHeader}
</>

    )
}

export default Header