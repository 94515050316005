import taggingHelper from '../helper/tagginhHelper'

const HeroTile = ({heroBannerImg,heroBannerImgMobile,bannerUrl,taggingUrl,heroBannerAltText}) => {
    return (
        <div className='hero-tile width-100 marg-bottom-10'>
            <a href={bannerUrl} onClick={() => taggingHelper(taggingUrl,3,heroBannerAltText,null,`Top Banner - ${bannerUrl}`)}>
                <picture>
                <source media="(max-width: 768px)" srcSet={heroBannerImgMobile}/>
                <img className="width-100" src={heroBannerImg} alt={heroBannerAltText}/>
                </picture>
            </a>
        </div>
    )
}

export default HeroTile
