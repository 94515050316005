const usePageIdentifier = () => {

    const currentPageURlInfo = document
    .querySelector("link[rel='canonical']")
    .getAttribute("href");
  

    const pageOriginalUrl = currentPageURlInfo.substring(currentPageURlInfo.lastIndexOf("/") + 1);
       
    const isHomepage = pageOriginalUrl.indexOf('thegoodguys.com.au') > -1 || pageOriginalUrl.indexOf('prodauth.thegoodguys.com.au') > -1 || pageOriginalUrl.indexOf('uatauth.thegoodguys.com.au') > -1 || pageOriginalUrl.indexOf('devauth.thegoodguys.com.au') > -1
    //console.log('isHomepage:',isHomepage)

    let PageUrl

    const productquiz = window.productquiz

    if(!isHomepage){
        PageUrl = pageOriginalUrl
    }else{
        PageUrl = 'homepage'
    }

    let pageType
    if(isHomepage){
        pageType = 'homepage'
    }else if(pageOriginalUrl === 'storecash'){
        pageType = 'storecash'
    }else if(pageOriginalUrl === 'new-in-store'){
        pageType = 'new-in-store'
    }else if(productquiz && productquiz !== undefined && productquiz.pagetype === 'L1' ){
        pageType = 'l1'
    }else {
        pageType = PageUrl
    }
    return {currentPageURlInfo,PageUrl,pageType,isHomepage};
};
export default usePageIdentifier;