import React , {useState} from 'react'
import Button from './Button'
import "./Tile.css"
import tagginhHelper from '../helper/tagginhHelper'

const Tile = props => {
    const [extendText, setExtendText] = useState(false)
    //console.log('props in tile :',props.tiledata)
    const {buttonColour,buttonText,buttonURL,buttonText2,buttonURL2,tileHeading,textLink,textLinkCopy,tileCopy,tileImage,imageAlt,buttonTitle,buttonTitle2,textTitle,border_colour,buttonDisabled} =  props.tiledata
    const tileType = props.tileType
    const position = +props.index + 1
    const longDescription = props.longDescription
    const largeTile = props.largeTile
    const isHomepage = props.isHomepage
    const PageUrl = props.PageUrl
    const tileCopy_long = tileCopy.length > 40
   
 

 const taggingUrl = "/" + buttonURL.substring(1)
 const taggingUrl2 = "/" + buttonURL2.substring(1)

const classList = `nisTile text-left bg-white noSelect ${tileType === 'grid' ? 'nisTileGrid width-100' : 'nisTileSlider marg-5 marg-bottom-15'}`

const styleObj = {
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "clip",
    whiteSpace: "normal",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    msTextOverflow: "clip",
  } 

  const addActiveClass = ()=>{
     setExtendText(!extendText)
  }

let paragrapgh
{tileType === 'grid' ? paragrapgh= <div className="padd-10" index={props.index} >
<h4 className="text-size-120 ">{tileHeading}</h4>
<div dangerouslySetInnerHTML={{__html : tileCopy}} >
</div> 
</div>: paragrapgh=<div className={`padd-10 padd-bottom-0 padd-top-0 tileCopy ${extendText ? 'active': ''} ${!extendText && longDescription || largeTile ? 'htmlCopyLarge': 'htmlCopy'} ${!extendText && longDescription && largeTile ? 'htmlCopyExpand': ''} ${!extendText && longDescription && !largeTile ? 'htmlCopyShort': ''}` } index={props.index}>
<h4 className="text-size-120 marg-bottom-5 nistileHeading">{tileHeading}</h4>
<div dangerouslySetInnerHTML={{__html : tileCopy}} style={!extendText && tileCopy_long ? styleObj : {}}>
</div> 
{longDescription && tileCopy_long ? <a className={`moreText text-info-sat ${!extendText ? 'disp-inline': 'disp-none'}`} onClick={addActiveClass}>More</a> : null}
 <a className={`text-info-sat ${extendText ? 'disp-inline': 'disp-none'}`} onClick={addActiveClass}>Less</a>
</div> }



const textLinkBtn = textLinkCopy && textLink ? <a className="linkBtn text-primary" href={textLink} onClick={()=> tagginhHelper(PageUrl,taggingUrl, position)} title={textTitle}><u>{textLinkCopy}</u></a> : null 


    return(
        <div className={classList} style={border_colour ? {borderColor:border_colour} :  {borderColor:'#ee32a2'}} >
           <a href={buttonURL} onClick={()=> tagginhHelper(PageUrl,taggingUrl, position)}><img className="img-responsive visible-all width-100" src={tileImage} alt={imageAlt ? imageAlt : 'new product at the Good Guys'} width="370" height="233" loading="lazy" data-lazyload="ondemand"/></a> 
          
           <div className={tileType !== 'grid'  ? `${longDescription ? 'nisCopyWrapperLarge': 'nisCopyWrapper'} ${largeTile ? 'nisCopyWrapperLtile': ''} ${extendText ? 'active': ''} ${!extendText && longDescription && !largeTile ? 'WarpperShort': ''}` : 'nisWrapperGrid'} >
               {paragrapgh }
                <div className={`btnWrapper padd-15 ${isHomepage && 'padd-top-0 padd-lg-top-15'} ${longDescription ? 'padd-bottom-10 padd-top-10': ''}`}>
                {(buttonURL && buttonTitle) || (buttonDisabled && buttonTitle) ? <Button buttonURL={buttonURL} buttonColour={buttonColour} buttonTitle={buttonTitle} buttonDisabled={buttonDisabled} onClick={()=> tagginhHelper(PageUrl,taggingUrl, position)}>{buttonText ? buttonText  : 'Shop Now' }</Button> : null}
               
                {(buttonURL2 && buttonTitle2) || (buttonDisabled && buttonTitle2)?  <Button buttonURL={buttonURL2} buttonTitle={buttonTitle2} buttonColour={buttonColour} buttonDisabled={buttonDisabled} onClick={()=> tagginhHelper(PageUrl,taggingUrl2, position)}>{buttonText2 ? buttonText2  : 'Shop Now' }</Button> : textLinkBtn}
                </div>
           </div>
        </div> 
    )

   

}
export default Tile


