import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";


const FilterHeader = ({ filterTitles, filterheading, filterTilesHandler, menuColor, firstFilter }) => {
  const [activeFilter, setActiveFilter] = useState(firstFilter)
  const [isDragging, setIsDragging] = useState(false)


const FilterLink = styled.a`
  display: inline-block;
  text-decoration: none;
  border-bottom: 3px solid #cecece;
  padding: 10px;
  padding-bottom: 5px;
  padding-right: 20px;
  padding-left: 15px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  color : ${menuColor ? menuColor : '#41AAF2'}!important;


  &:hover,
  &:active {
    color: #000 !important;
     text-decoration: none;
    border-bottom : 3px solid;
    border-bottom-color:${menuColor ? menuColor : '#41AAF2'}!important;
    text-decoration: none !important;
  }

  &.active {
    color: #000 !important;
    border-bottom : 3px solid;
    border-bottom-color: ${menuColor ? menuColor : '#41AAF2'}!important;
  }
`;

const taggingHelper = (title) => {
    window.dataLayer.push({
      event: 'whats_new_in_store',
      TGGEventCategory: 'Filter Menu',
      TGGEventAction: 'Latest Offers Menu',
      TGGEventLabel: `${title}`
    })
  }

  const filtersArray = filterTitles.split(',');
  //filtersArray.unshift('ALL OFFERS');


  const handleBeforeChange = () => {
    setIsDragging(true)
  }
  const handleAfterChange = () => {
    setIsDragging(false)
  }


  
  const handleClick = (e,el) => {
     // console.log('el',el)
    if (isDragging) {
        e.preventDefault();
      } else {
    setActiveFilter(el);
    filterTilesHandler(el);
    taggingHelper(el)
  }
}

  const settings = {
    className: "slider variable-width gallary",
  arrows: false,
  dots: false,
  infinite: false,
  speed: 200,
  slidesToScroll: 2,
  adaptiveHeight: true,
  variableWidth: true,
  draggable: true
  };

  return (
    <Fragment >
    <h1 className="text-center text-black text-upper">{filterheading ? filterheading : 'latest offers'}</h1>  

       <div className="header-wrapper">
             <span className="padd-10 padd-left-0 padd-bottom-5 non-selectable text-black text-bold text-upper disp-inlineblock">FILTER:</span>
       
           <div className="slider-wrapper">
               <Slider {...settings}
                beforeChange={handleBeforeChange}
                afterChange={handleAfterChange}
              >
                     {filtersArray.map((el, index) => (
                     <FilterLink
                         key={index}
                         onClick={(e) => handleClick(e,el)}
                         className={`non-selectable text-center ${activeFilter === el ? 'active' : ''}`} >
                         {el}
                     </FilterLink>
                     ))}
                 </Slider>
           </div>
       </div>
  
 </Fragment>
  );
};

export default FilterHeader;