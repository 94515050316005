import React  from 'react'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Tile from './Tile'
import CustomTile from './CustomTile'
import HeroTile from './HeroTile'
import Header from './Header'
import FilterHeader from '../filterNav/FilterHeader'

const TilesGrid = props => {
  const { allNisData, pageType, svgImage, filterTitles, filterheading, filterTilesHandler, filteredData, heroBannerImg, heroBannerImgMobile, heroBannerAltText, menuColor, bannerUrl, firstFilter, PageUrl, L1CategoryNavWidget } = props
    const categoryTitle = allNisData[0].PageURL
    const taggingUrl =
    pageType === 'homepage'
      ? 'https://www.thegoodguys.com.au'
      : `https://www.thegoodguys.com.au/${categoryTitle}`
 

    return(
      allNisData && allNisData !== undefined &&
<div className={!L1CategoryNavWidget ? "container" : ""}>
  {filterTitles !== undefined && filterTitles.length > 0 ? <FilterHeader filterTitles={filterTitles} filterheading={filterheading} filterTilesHandler={filterTilesHandler} menuColor={menuColor} firstFilter={firstFilter}/> : null}
  <Header svgImage={svgImage} pageType={pageType} L1CategoryNavWidget={L1CategoryNavWidget} PageUrl={PageUrl}/>
 {heroBannerImg !=='' && heroBannerImgMobile !=='' && <HeroTile heroBannerImg={heroBannerImg} heroBannerImgMobile={heroBannerImgMobile} heroBannerAltText={heroBannerAltText} bannerUrl={bannerUrl} taggingUrl={taggingUrl}/>} 
  <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}} >
    <Masonry gutter= '15px'>
      {filteredData && filteredData !== undefined && filteredData.map((el,index) => {
        if (!el.hero_tile)
          return (
            <Tile key={el.ID} tiledata={el} tileType='grid' pageType={pageType} index={index} taggingUrl={taggingUrl} PageUrl={PageUrl}/>
          );
        else 
          return (<CustomTile key={el.ID} customTileData={el} index={index} taggingUrl={taggingUrl}/>);
      })}
    </Masonry>
  </ResponsiveMasonry>
</div>
    )
}
export default TilesGrid