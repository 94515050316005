import React, { useState, useEffect } from 'react';
import { svgCode } from '../helper/spinnerSvg';

const TilePlaceholder = ({ L1CategoryNavWidget }) => {
  const [itemCount, setItemCount] = useState(calculateRowCount());

  function calculateRowCount() {
    if (window.innerWidth > 1023) {
      return L1CategoryNavWidget ? 3 : 4;
    } else if (767 < window.innerWidth && window.innerWidth < 1024) {
      return 3;
    } else if (425 < window.innerWidth && window.innerWidth < 768) {
      return 2;
    } else if (window.innerWidth < 426) {
      return 1;
    }
  }

  useEffect(() => {
    function handleResize() {
      setItemCount(calculateRowCount());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [L1CategoryNavWidget]); // Re-run effect when L1CategoryNavWidget changes

  const renderPlaceholderItems = () => {
    const items = [];
    for (let i = 0; i < itemCount; i++) {
      items.push(
        <div key={i} className="placeholder-item marg-right-10 marg-bottom-10">
          {svgCode}
        </div>
      );
    }
    return items;
  };

  return (
    <div className={`tile-placeholder ${!L1CategoryNavWidget ? 'container' : ''}`}>
      <div className="placeholder-items">
        {renderPlaceholderItems()}
      </div>
    </div>
  );
};

export default TilePlaceholder;
